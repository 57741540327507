body.light {
	/* Brand */

	/* Primary */
	--brand-primary: #0902D4;
	--brand-primary-hover: #060198;
	--brand-primary-tint: #03004D;
	--brand-primary-off: #99AFFF;
	--brand-primary-dark: #554FFD;

	/* Filters */
	--filter-to-primary: brightness(0) saturate(100%) invert(15%) sepia(80%) saturate(4416%) hue-rotate(244deg) brightness(77%) contrast(145%);

	/* Secondary */
	--brand-secondary: #800DD3;
	--brand-secondary-hover: #620AA2;
	--brand-secondary-tint: #3B0066;
	--brand-secondary-off: #DFB2FF;

	/* Tertiary */
	--brand-tertiary: #12C2E3;
	--brand-tertiary-hover: #0998B2;
	--brand-tertiary-tint: #006C80;
	--brand-tertiary-off: #B2F3FF;

	/* Contrast */
	--light: #FFFFFF;
	--dark: #080810;
	--brand-contrast: #FAFAFA;
	--brand-gradient: linear-gradient(221deg, var(--brand-primary-hover) 100%, var(--brand-primary-tint) 50%, var(--brand-primary) 0%);

	/* Shadow */
	--backdrop: rgba(0, 0, 0, 50%);
	--shadow-0: #0E1B2322;

	/* Gray */
	--gray-0: #132027;
	--gray-1: #152935;
	--gray-2: #23394B;
	--gray-3: #354C60;
	--gray-4: #495E76;
	--gray-5: #62728B;
	--gray-6: #808CA3;
	--gray-7: #929DB2;
	--gray-8: #A8B3C3;
	--gray-9: #BEC8D4;
	--gray-10: #D7DDE5;
	--gray-11: #E6EBF1;
	--gray-12: #F0F3F6;
	--gray-13: #F4F7FA;
	--gray-14: var(--light);

	/* Error */
	--error-normal: #E34C4C;
	--error-hover: #BD3539;
	--error-tint: #991F1F;
	--error-off: #FF9999;
	--error-background: #FEF6F6;
	--error-background-alt: #F2CBCA;
	--dark-error-hover: #FE7F7F;
	--dark-error-tint: #FFCCCC;
	--light-error-tint: var(--error-tint);

	/* Warning */
	--warning-normal: #F6B322;
	--warning-hover: #CC9214;
	--warning-tint: #996F15;
	--warning-off: #FFDF99;
	--warning-background: #FFFBF4;
	--warning-background-alt: #FDEBC4;
	--light-warning-off: var(--warning-off);
	--light-warning-tint: var(--warning-tint);

	/* Success */
	--success-normal: #56BD5B;
	--success-hover: #3E9645;
	--success-tint: #26802B;
	--success-off: #99FF9E;
	--success-background: #F6FCF7;
	--success-background-alt: #D0E9D0;
	--light-success-off: var(--success-off);
	--light-success-tint: var(--success-tint);

	/* Information */
	--information-normal: #0099FA;
	--information-hover: #007BD4;
	--information-tint: #005999;
	--information-off: #99D7FF;
	--information-background: #F2FAFF;
	--information-background-alt: #B6D7F0;
	--light-information-off: var(--information-off);

	/* Processing */
	--processing-normal: #902BD9;
	--processing-hover: #6800B2;
	--processing-tint: #52008C;
	--processing-off: #CA80FF;
	--processing-background: #FCF7FF;
	--processing-background-alt: #DAB6F6;

	/* Colors */
	--special-green-1: #00B4A0;
	--special-green-2: #50E3C2;
	--special-green-3: #61BD4F;
	--special-green-4: #77DC01;
	--special-orange-1: #F3AB4B;
	--special-orange-2: #FFAE1C;
	--special-yellow-1: #F0CC18;
	--special-yellow-2: #FFE224;
	--special-violet-1: #9900EF;
	--special-violet-2: #9682E7;
	--special-purple-1: #9F5BDA;
	--special-purple-2: #C377E0;
	--special-red-1: #CC1B00;
	--special-red-2: #FF2200;
	--special-blue-1: #1C30CC;
	--special-blue-2: #1990F9;
	--special-blue-3: #EBF4FF;
	--special-pink-1: #BB47A9;

	/* Alerts */
	--background-primary: #F7FBFF;
	--background-button: #EBF4FF;
}
