body.dark {
	/* Brand */
	--brand-primary: #554FFD;
	--brand-primary-hover: #6B8CFF;
	--brand-primary-tint: #B1C1FB;
	--brand-primary-off: #231F99;
	--brand-primary-light-tint: #03004D;

	/* Filters */
	--filter-to-primary: brightness(0) saturate(100%) invert(33%) sepia(78%) saturate(5778%) hue-rotate(238deg) brightness(104%) contrast(98%);

	/* Secondary */
	--brand-secondary: #B44DFF;
	--brand-secondary-hover: #CA80FF;
	--brand-secondary-tint: #DFB2FF;
	--brand-secondary-off: #6C0BB2;

	/* Tertiary */
	--brand-tertiary: #0AD2F6;
	--brand-tertiary-hover: #69E8FF;
	--brand-tertiary-tint: #A9F1FE;
	--brand-tertiary-off: #01778C;

	/* Contrast */
	--light: #FFFFFF;
	--dark: #11111A;
	--brand-contrast: #FAFAFA;
	--brand-gradient: linear-gradient(221deg, var(--brand-primary-hover) 100%, var(--brand-primary-tint) 50%, var(--brand-primary) 0%);

	/* Shadow */
	--backdrop: rgba(0, 0, 0, 50%);
	--shadow-0: #595B7022;

	/* Gray */
	--gray-0: #FAFAFA;
	--gray-1: #D8DDE4;
	--gray-2: #B8C1CE;
	--gray-3: #9CA6B7;
	--gray-4: #818DA1;
	--gray-5: #69758B;
	--gray-6: #586379;
	--gray-7: #4D556A;
	--gray-8: #42475C;
	--gray-9: #363A4E;
	--gray-10: #2D2E3F;
	--gray-11: #242533;
	--gray-12: #1A1A26;
	--gray-13: #14141F;
	--gray-14: var(--dark);

	/* Error */
	--error-normal: #FF6666;
	--error-hover: #FE7F7F;
	--error-tint: #FFCCCC;
	--error-off: #BD1318;
	--error-background-alt: #330A0A;
	--error-background: var(--gray-13);
	--dark-error-hover: var(--error-hover);
	--dark-error-tint: var(--error-tint);
	--light-error-tint: #991F1F;

	/* Warning */
	--warning-normal: #FFBC2B;
	--warning-hover: #FFC74D;
	--warning-tint: #FFDF99;
	--warning-off: #AF8019;
	--warning-background-alt: #332507;
	--warning-background: var(--gray-13);
	--light-warning-off: #FFDF99;
	--light-warning-tint: #996F15;

	/* Success */
	--success-normal: #4FF157;
	--success-hover: #92FF97;
	--success-tint: #CCFFCF;
	--success-off: #3AAE40;
	--success-background-alt: #0B260D;
	--success-background: var(--gray-13);
	--light-success-off: #99FF9E;
	--light-success-tint: #26802B;

	/* Information */
	--information-normal: #22A9FF;
	--information-hover: #66C3FF;
	--information-tint: #CCEBFF;
	--information-off: #007BC9;
	--information-background-alt: #001E33;
	--information-background: var(--gray-13);
	--light-information-off: #99D7FF;

	/* Processing */
	--processing-normal: #A933FF;
	--processing-hover: #BF66FF;
	--processing-tint: #EACCFF;
	--processing-off: #7F14CC;
	--processing-background-alt: #1E0033;
	--processing-background: var(--gray-13);

	/* Colors */
	--special-green-1: #00B4A0;
	--special-green-2: #50E3C2;
	--special-green-3: #61BD4F;
	--special-green-4: #77DC01;
	--special-orange-1: #F3AB4B;
	--special-orange-2: #FFAE1C;
	--special-yellow-1: #F0CC18;
	--special-yellow-2: #FFE224;
	--special-violet-1: #9900EF;
	--special-violet-2: #9682E7;
	--special-purple-1: #9F5BDA;
	--special-purple-2: #C377E0;
	--special-red-1: #CC1B00;
	--special-red-2: #FF2200;
	--special-blue-1: #1C30CC;
	--special-blue-2: #1990F9;
	--special-blue-3: #EBF4FF;
	--special-pink-1: #BB47A9;

	/* Alerts */
	--background-primary: #151526;
	--background-button: #1C1B4D;
}
