/* Ionic selectable border */
.ionic-selectable-cover {
	border: solid 1px var(--gray-9) !important;
	border-radius: 4px !important;
	color: var(--gray-0);
	height: 45px !important;
}

/* Ionic selectable on hover */
.ionic-selectable-cover:hover {
	border: solid 1px var(--gray-1) !important;
}

/* Ionic selectable icon */
.ionic-selectable-icon-inner {
	top: 50% !important;
	left: 0 !important;
}

/* Ionic selectable container */
ionic-selectable > .ionic-selectable-inner {
	padding-right: 8px;
	padding-left: 13px;
}

/* Ionic selectable container */
ionic-selectable > .ionic-selectable-inner > .ionic-selectable-value {
	display: flex;
	align-items: center;
	height: 45px;
	padding: 0;
}

/* Ionic selectable text container */
ionic-selectable > .ionic-selectable-inner > .ionic-selectable-value > .ionic-selectable-value-item {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: var(--gray-0);
	max-width: 160px;
	min-width: 16px;
	height: 45px;
	display: flex;
	align-items: center;
}

/* Ionic selectable text container when option is selected */
ionic-selectable > .ionic-selectable-inner > .ionic-selectable-value > span {
	height: 45px;
	max-width: 100%;
	display: flex;
	align-items: center;
}

/* Ionic selectable text when option is selected */
ionic-selectable > .ionic-selectable-inner > .ionic-selectable-value > span > .ionic-selectable-value-item {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	min-width: 16px;
}

/* Ionic selectable icon */
ionic-selectable > .ionic-selectable-inner > .ionic-selectable-icon {
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Ionic selectable icon inner style */
ionic-selectable > .ionic-selectable-inner > .ionic-selectable-icon > .ionic-selectable-icon-inner {
	position: relative !important;
	top: 0 !important;
	color: var(--gray-7);
}

/* Ion Input */
.input-fill-outline.sc-ion-input-md-h {
	min-height: 45px !important;

	--padding-end: 0 !important;
}

/* Ion Select */
ion-select {
	min-height: 45px;
}

.select-wrapper-inner {
	width: 100% !important;
	justify-content: space-between !important;
}

/* Option selector popover */
ion-popover::part(content) {
	--width: fit-content;
	--max-width: 95%;
	--min-width: 200px;
}

/* Ion label Form label disabled style (keep opacity) */
.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs),
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
	cursor: default;
	opacity: 1;
	pointer-events: none;
}

/* Alert Size */
.sc-ion-alert-md-h {
	--min-width: 80%;
	--max-width: 90%;
	--width: auto;
	--min-height: auto;
	--height: auto;
	--max-height: 90%;
}

/* Options selector modal text wrap. */
.alert-radio-label.sc-ion-alert-md {
	white-space: pre-line !important;
}

.alert-checkbox-label.sc-ion-alert-md {
	white-space: pre-line !important;
}

.alert-tappable.alert-radio {
	height: auto;
	contain: content;
}
