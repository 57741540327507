::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	margin-bottom: 4px;
	margin-top: 4px;
}

::-webkit-scrollbar-track {
	background: var(--gray-12);
}

::-webkit-scrollbar-thumb {
	background: var(--gray-8);
	border-radius: 90px;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--gray-4);
}
