ion-card {
	box-shadow: var(--shadow);
}

body {
	--shadow: var(--shadow-0) 0 3px 1px -2px, var(--shadow-0) 0 2px 2px 0, var(--shadow-0) 0 1px 5px 0;

	color: var(--gray-1);

	--color: var(--gray-1);

	font-family: "Open Sans", sans-serif;

	--ion-font-family: "Open Sans", sans-serif;
	--ion-default-font: "Open Sans", sans-serif;
	--ion-toolbar-background: var(--gray-14);
	--ion-item-background: var(--gray-14);
	--ion-item-background-activated: var(--gray-12);
	--ion-background-color: var(--gray-14);
	--ion-background-color-rgb: var(--gray-14);
	--ion-text-color: var(--gray-1);
	--ion-text-color-rgb: var(--gray-1);
	--ion-border-color: var(--gray-10);
	--ion-color-primary: var(--brand-primary);
	--ion-color-primary-rgb: var(--brand-primary);
	--ion-color-primary-contrast: var(--brand-contrast);
	--ion-color-primary-contrast-rgb: var(--brand-contrast);
	--ion-color-primary-shade: var(--brand-primary-hover);
	--ion-color-primary-tint: var(--brand-primary-tint);
	--ion-color-secondary: var(--brand-primary);
	--ion-color-secondary-rgb: var(--brand-primary);
	--ion-color-secondary-contrast: var(--brand-contrast);
	--ion-color-secondary-contrast-rgb: var(--brand-contrast);
	--ion-color-secondary-shade: var(--brand-primary-hover);
	--ion-color-secondary-tint: var(--brand-primary-tint);
	--ion-color-tertiary: var(--brand-primary);
	--ion-color-tertiary-rgb: var(--brand-primary);
	--ion-color-tertiary-contrast: var(--brand-contrast);
	--ion-color-tertiary-contrast-rgb: var(--brand-contrast);
	--ion-color-tertiary-shade: var(--brand-primary-hover);
	--ion-color-tertiary-tint: var(--brand-primary-tint);
	--ion-color-success: var(--success-normal);
	--ion-color-success-rgb: var(--success-normal);
	--ion-color-success-contrast: var(--light);
	--ion-color-success-contrast-rgb: var(--light);
	--ion-color-success-shade: var(--success-hover);
	--ion-color-success-tint: var(--success-tint);
	--ion-color-warning: var(--warning-normal);
	--ion-color-warning-rgb: var(--warning-normal);
	--ion-color-warning-contrast: var(--light);
	--ion-color-warning-contrast-rgb: var(--light);
	--ion-color-warning-shade: var(--warning-hover);
	--ion-color-warning-tint: var(--warning-tint);
	--ion-color-danger: var(--error-normal);
	--ion-color-danger-rgb: var(--error-normal);
	--ion-color-danger-contrast: var(--light);
	--ion-color-danger-contrast-rgb: var(--light);
	--ion-color-danger-shade: var(--error-hover);
	--ion-color-danger-tint: var(--error-tint);
	--ion-color-dark: var(--gray-1);
	--ion-color-dark-rgb: var(--gray-1);
	--ion-color-dark-contrast: var(--gray-8);
	--ion-color-dark-contrast-rgb: var(--gray-8);
	--ion-color-dark-shade: var(--gray-1);
	--ion-color-dark-tint: var(--gray-2);
	--ion-color-medium: var(--gray-4);
	--ion-color-medium-rgb: var(--gray-4);
	--ion-color-medium-contrast: var(--gray-1);
	--ion-color-medium-contrast-rgb: var(--gray-1);
	--ion-color-medium-shade: var(--gray-5);
	--ion-color-medium-tint: var(--gray-6);
	--ion-color-light: var(--gray-9);
	--ion-color-light-rgb: var(--gray-9);
	--ion-color-light-contrast: var(--gray-1);
	--ion-color-light-contrast-rgb: var(--gray-1);
	--ion-color-light-shade: var(--gray-9);
	--ion-color-light-tint: var(--gray-8);
	--ion-color-step-50: var(--gray-13);
	--ion-color-step-100: var(--gray-13);
	--ion-color-step-150: var(--gray-12);
	--ion-color-step-200: var(--gray-12);
	--ion-color-step-250: var(--gray-10);
	--ion-color-step-300: var(--gray-9);
	--ion-color-step-350: var(--gray-9);
	--ion-color-step-400: var(--gray-8);
	--ion-color-step-450: var(--gray-8);
	--ion-color-step-500: var(--gray-7);
	--ion-color-step-550: var(--gray-7);
	--ion-color-step-600: var(--gray-6);
	--ion-color-step-650: var(--gray-6);
	--ion-color-step-700: var(--gray-5);
	--ion-color-step-750: var(--gray-4);
	--ion-color-step-800: var(--gray-3);
	--ion-color-step-850: var(--gray-2);
	--ion-color-step-900: var(--gray-1);
	--ion-color-step-950: var(--gray-0);
}
