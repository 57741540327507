/* Headline 1 Semibold */
.uno-h1 {
	font-family: "Open Sans", sans-serif;
	font-size: 1.75rem;
	line-height: 36px;
	font-weight: 600;
}

/* Headline 2 Semibold */
.uno-h2 {
	font-family: "Open Sans", sans-serif;
	font-size: 1.5rem;
	line-height: 32px;
	font-weight: 600;
}

/* Headline 3 Semibold */
.uno-h3 {
	font-family: "Open Sans", sans-serif;
	font-size: 1.25rem;
	line-height: 28px;
	font-weight: 600;
}

/* Headline 4 Semibold */
.uno-h4 {
	font-family: "Open Sans", sans-serif;
	font-size: 1.125rem;
	line-height: 28px;
	font-weight: 600;
}

/* Headline 5 Semibold */
.uno-h5 {
	font-family: "Open Sans", sans-serif;
	font-size: 1rem;
	line-height: 24px;
	font-weight: 600;
}

/* Headline 6 Semibold */
.uno-h6 {
	font-family: "Open Sans", sans-serif;
	font-size: 0.875rem;
	line-height: 20px;
	font-weight: 600;
}

/* Headline 7 Semibold */
.uno-h7 {
	font-family: Nunito, sans-serif;
	font-size: 0.75rem;
	line-height: 16px;
	font-weight: 600;
	text-transform: uppercase;
}

/* Headline 8 Semibold */
.uno-h8 {
	font-family: Nunito, sans-serif;
	font-size: 0.625rem;
	line-height: 16px;
	font-weight: 600;
	text-transform: uppercase;
}

/* Headline 1 Bold */
.uno-h1-bold {
	font-family: "Open Sans:700", sans-serif;
	font-size: 1.75rem;
	line-height: 36px;
	font-weight: 700;
}

/* Headline 2 Bold */
.uno-h2-bold {
	font-family: "Open Sans:700", sans-serif;
	font-size: 1.5rem;
	line-height: 32px;
	font-weight: 700;
}

/* Headline 3 Bold */
.uno-h3-bold {
	font-family: "Open Sans:700", sans-serif;
	font-size: 1.25rem;
	line-height: 28px;
	font-weight: 700;
}

/* Headline 4 Bold */
.uno-h4-bold {
	font-family: "Open Sans:700", sans-serif;
	font-size: 1.125rem;
	line-height: 28px;
	font-weight: 700;
}

/* Headline 5 Bold */
.uno-h5-bold {
	font-family: "Open Sans:700", sans-serif;
	font-size: 1rem;
	line-height: 24px;
	font-weight: 700;
}

/* Headline 6 Bold */
.uno-h6-bold {
	font-family: "Open Sans:700", sans-serif;
	font-size: 0.875rem;
	line-height: 20px;
	font-weight: 700;
}

/* Headline 7 Bold */
.uno-h7-bold {
	font-family: Nunito, sans-serif;
	font-size: 0.75rem;
	line-height: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

/* Headline 8 Bold */
.uno-h8-bold {
	font-family: Nunito, sans-serif;
	font-size: 0.625rem;
	line-height: 16px;
	font-weight: bold;
	text-transform: uppercase;
}

/* Paragraph 1 */
.uno-p1 {
	font-family: "Open Sans", sans-serif;
	font-size: 1rem;
	line-height: 24px;
	font-weight: 400;
}

/* Paragraph 2 */
.uno-p2 {
	font-family: "Open Sans", sans-serif;
	font-size: 0.875rem;
	line-height: 20px;
	font-weight: 400;
}

/* Paragraph 3 */
.uno-p3 {
	font-family: "Open Sans", sans-serif;
	font-size: 0.75rem;
	line-height: 16px;
	font-weight: 400;
}

/* Style for Large Buttons */
.uno-large-button {
	font-family: Nunito, sans-serif;
	font-size: 1rem;
	font-weight: 600;
}

/* Style for Medium Buttons */
.uno-medium-button {
	font-family: Nunito, sans-serif;
	font-size: 0.875rem;
	font-weight: 600;
}

/* Style for Dmall Buttons */
.uno-small-button {
	font-family: Nunito, sans-serif;
	font-size: 0.75rem;
	font-weight: 600;
}

/* Style for X Small Buttons */
.uno-x-small-button {
	font-family: Nunito, sans-serif;
	font-size: 0.625rem;
	font-weight: 700;
	text-transform: uppercase;
}

/* Small Text */
.uno-small {
	font-family: "Open Sans", sans-serif;
	font-size: 0.625rem;
	line-height: 16px;
	font-weight: 400;
}
